// import React from 'react';

// const Footer = () => {
//     return (
//       <div
//       id="kt_app_footer"
//       className="app-footer d-flex flex-column flex-md-row align-items-center flex-center flex-md-stack py-2 py-lg-4"
//       style={{ backgroundColor: '#17191E', color: 'white', bottom: 0, width: '100%' }}
//     >
//       <div className="text-dark order-2 order-md-1 mx-10">
//         <span className="text-white fw-semibold me-1">© 2023</span>
//         <a href=" " target="_blank" className="text-white text-hover-primary">CollabWorx | All Rights Reserved.</a>
//       </div>
//       <ul className="menu menu-white menu-hover-primary fw-semibold order-1 mx-7">
//         <li className="menu-item">
//           <a href=" " target="_blank" className="menu-link px-2">Privacy Policy</a>
//         </li>
//         <li className="menu-item">
//           <a href=" " target="_blank" className="menu-link px-2">Terms of Service</a>
//         </li>
//       </ul>
//     </div>
//     )
// }

// export default Footer;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  useEffect(() => {
    document.body.removeAttribute("style");
  }, []);
  return (
    <div
      id="kt_app_footer"
      className="app-footer d-flex flex-column flex-md-row align-items-center flex-center flex-md-stack pt-1 staticblue rounded-top footerstyling"
    >
      <div className="text-dark order-2 order-md-1 mx-10">
        <span className="text-white fw-semibold me-1">© 2024</span>
        <span className="text-white ">CollabWorx | All Rights Reserved.</span>
      </div>
      <ul className="menu menu-white fw-semibold order-1 mx-7 ">
        {/* <li className="menu-item hover-elevate-up">
          <Link to="/subscription-plan" className="menu-link px-2">
          subscription plan
          </Link>
        </li> */}
        <li className="menu-item hover-elevate-up">
          <Link to="/eula" target="_blank" className="menu-link px-2">
            EULA Policy
          </Link>
        </li>
        <li className="menu-item hover-elevate-up">
          <Link to="/termsofuse" target="_blank" className="menu-link px-2">
            Terms of use
          </Link>
        </li>
        <li className="menu-item hover-elevate-up">
          <Link to="/shipping-policy" target="_blank" className="menu-link px-2">
            Shipping policy
          </Link>
        </li>
        <li className="menu-item hover-elevate-up">
          <Link to="/refund-policy" target="_blank" className="menu-link px-2">
            Cancellation and refund
          </Link>
        </li>
        <li className="menu-item hover-elevate-up">
          <Link to="/contact-us" target="_blank" className="menu-link px-2">
            Contact us
          </Link>
        </li>
        <li className="menu-item hover-elevate-up">
          <Link to="/aboutus" target="_blank" className="menu-link px-2">
            About us
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
