import React from 'react'
import './styles/page-loader.css'
const PageLoader = () => {
  return (
    <div class="spin center">
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
    <div class="spin-blade"></div>
</div>
  )
}

export default PageLoader
